const amplifyconfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: "ap-southeast-1:37f16c76-52e3-4004-b293-e677d3374629",
    // identityPoolId: "ap-southeast-1:4a8d1d37-74b0-4c7e-9afe-6c38d00e1d21",

    // REQUIRED - Amazon Cognito Region
    userPoolId: "ap-southeast-1_Fn1i6z1cX",
    // userPoolId: "ap-southeast-1_zGvOoIII7",
    region: "ap-southeast-1",
    userPoolWebClientId: "7t2gt3s9r6pemhg05t9s3n7mgk",
    // userPoolWebClientId: "3nvhbpo02983af4uilnsho2e91",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "upload",
        endpoint:
          "https://w4b3d00222.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
      },
    ],
  },
};

export default amplifyconfig;
