import React, { Component } from "react";
import { VERSIONS } from "../../consts/versions";
import { Icon } from "antd";
class Version extends Component {
  render() {
    return (
      <div className={"version"}>
        <div>
          <Icon type="alert" /> {" "}
          ( V {VERSIONS[VERSIONS.length - 1].id})
        </div>
      </div>
    );
  }
}

// const styles = {
//   version: {
//     backgoundColor: ""
//   }
// };

export default Version;
