import React from "react";
import {
    Layout,
  } from "antd";

import Version from "./Version";
import { LDX_COLOR_ORANGE,LDX_COLOR_GREY } from "../../consts/colors";

const {  Header, Footer } = Layout;
const LoginUI = (props) => {
    return (
        <div>
          <Header id="header" style={styles.header}>
              <span
                  style={{ fontSize: "200%", fontWeight: 700, color: LDX_COLOR_ORANGE }}
              >
                  S3
              </span>
              <span style={{ fontSize: "200%", fontWeight: 200, color: LDX_COLOR_GREY }}>
                  PORTAL
              </span>
              <span style={styles.headerTitleStandard}> @ </span>
              <span style={styles.headerTitleStandard}>Lucence Diagnostics</span>
          </Header>
          <Footer style={styles.footer} >
          Copyright © 2019 - Lucence Diagnostics. All Rights Reserved.
        </Footer>
        <Version />
       </div>
  );
}

export default LoginUI;
  
const styles = {
  
    header: { 
      color:LDX_COLOR_GREY,
      background:"#fff",
      textAlign:'center',
      lineHeight: '50px',
      height : '70px',
      position: 'relative',
      width : "100%",
      // paddingLeft:"40%",
      left:-35,
      top: -30,
      zIndex: -1,
    },
    headerTitleStandard : {
      fontSize:"90%",
      fontWeight:200,
      background:"#fff",
      color:LDX_COLOR_GREY
    },
    footer: { 
      textAlign: "center" ,
      background:"#fff",
      color:"#454b52",
      fontSize : "85%",
      position: 'fixed',
      width : "100%",
      left: 0,
      bottom: 20,
       zIndex: -2,
    }
  };
