import React, { Component } from "react";
import {
  Layout,
  Menu,
  Icon,
  Popover,
} from "antd";

import { Switch, Route, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Auth } from "aws-amplify";
import { authStore } from "../../store/auth";
import AsyncComponent from "../optimize/AsyncComponent";
import Version from "./Version";
import { LDX_COLOR_ORANGE,LDX_COLOR_GREY } from "../../consts/colors";

import "./mainframe.css";

const MenuItem = Menu.Item;
const {  Header, Content, Footer } = Layout;

class MainFrame extends Component {
  constructor(props) {
    super(props);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleLogOut() {
    console.log("log out click");
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));

    window.location = "/";
  }

  componentDidMount() {
  }


  render() {
    let name = authStore.username;

    const menu = (
      <Menu style={{ border: 0 }}>
        <MenuItem onClick={() => this.handleLogOut()}>
          <Icon type="logout" />
          Logout
        </MenuItem>
      </Menu>
    );


    return (
        <Layout style={{minHeight:"110vh",background:"#fff"}}>
          <Header id="header" style={styles.header}>
            <span style={{fontSize:"200%",fontWeight:700,color:LDX_COLOR_ORANGE}}>
              S3
            </span>
            <span style={{fontSize:"200%",fontWeight:200,color:"#fff"}}>
              PORTAL
            </span>
            <span style={styles.headerTitleStandard}>
            {" "}@{" "}
            </span>
            <span style={styles.headerTitleStandard}>
             Lucence Diagnostics
            </span>
            <span style={{float:'right',...styles.headerTitleStandard}}>
              <Popover
                  placement="bottomLeft"
                  content={<div> {menu}</div>}
                  trigger="hover"
                >
                  <span>
                    {" "}
                    <Icon type="setting" /> {name}{" "}
                  </span>
              </Popover>
            </span>
          
          </Header>

          <Content
            style={{
              marginLeft: "3%",
              marginRight: "3%",
              marginTop:"1%"
            }}
          >
            {Routes}
          </Content>
          <Version />
          <Footer style={styles.footer}>
            Copyright © 2019 - Lucence Diagnostics. All Rights Reserved.
          </Footer>
        </Layout>
    );
  }
}

const AsyncPortalMaster = AsyncComponent(() =>
  import("../portal/PortalMaster")
);

//  because of the diff alg in react. do not use HOC in render method
// so need to extract Routes and assign to variable
const Routes = (
  <Switch>
    <Route path="/" component={AsyncPortalMaster} />
  </Switch>
);

const styles = {
  ava: {
    backgroundColor: "#f56a00",
    verticalAlign: "middle",
    float: "right",
    margin: 20
  },
  container: {
    // display:"table-cell",
    float: "right",
    textAlign: "center"
  },
  appstore: {
    verticalAlign: "middle",
    display: "table-cell"
    // fontSize:45
  },
  header: { 
    color:"#fff",
    background:LDX_COLOR_GREY,
    textAlign:'center',
    paddingLeft:"7%",
    lineHeight: '50px',
    height : '50px'
  },
  headerTitleStandard : {
    fontSize:"90%",
    fontWeight:200,
    color:"#fff"
  },
  footer: { 
    textAlign: "center" ,
    background:"#fff",
    color:"#454b52",
    fontSize : "90%",
    height : "1vh"
  }
};


export default withRouter(observer(MainFrame));

