export const VERSIONS = [
  {
    id: "alpha",
    description: "alpha version",
  },
  {
    id: "beta",
    description: "beta version",
  },
  {
    id: "0.1.0",
    description: "enabled cognito user pool",
  },
];
