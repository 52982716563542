import {
  Authenticator,
  SignIn,
  ConfirmSignIn,
  // Greetings,
  RequireNewPassword,
  VerifyContact,
  ForgotPassword,
  TOTPSetup
} from "aws-amplify-react";

import React, { Component } from "react";
import { withRouter } from "react-router";
import App from "./App";
import { authStore } from "./store/auth";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react";
import { Hub } from "aws-amplify";
class OwnAutenticator extends Component {
  constructor(props) {
    super(props);

    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
  }

  handleAuthStateChange(state) {
    // authStore.getUser()
    if (state === "signedIn") {
      console.log("signed in");

      Auth.currentAuthenticatedUser({
        bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(authData => {
          console.log("authData", authData);
          authStore.setEmail(
            authData["signInUserSession"]["idToken"]["payload"]["email"]
          );
          authStore.setUsername(authData["username"]);
          let groups =
            authData["signInUserSession"]["accessToken"]["payload"][
              "cognito:groups"
            ];
          authStore.setGroups(groups);
          let jwtToken =
            authData["signInUserSession"]["accessToken"]["jwtToken"];
          localStorage.setItem("jwtToken", "AWS " + jwtToken);

          Hub.dispatch("auth", { event: "UserDataReady" }, "Auth");
        })
        .catch(err => {
          console.log(err);
        });
      Auth.currentUserInfo().then(d => {
        console.log("userinfo", d);
      });
    }
  }

  render() {
    return (
      <div
      // style={styles.main}
      >
        <div
        // style={styles.container}
        >
          <Authenticator
            hideDefault={true}
            onStateChange={this.handleAuthStateChange}
          >
            <SignIn />
            <ConfirmSignIn />
            <RequireNewPassword />
            <VerifyContact />
            <ForgotPassword />
            <TOTPSetup />
            <App style={styles.app} />
          </Authenticator>
        </div>
      </div>
    );
  }
}

const styles = {
  main: {
    // backgroundImage: `url(${BackGoundImg})`,
    width: "100%",
    height: "100vh",
    position: "absolute"
  },
  container: {
    position: "absolute",
    right: 150,
    top: 150
  },
  app: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    left: 0,
    top: 0
  }
};

// const federated = {
//   google_client_id:
//     "163162952431-heilltnqemjr8r464gd9fu894bl5a706.apps.googleusercontent.com"
// };
export default withRouter(observer(OwnAutenticator));
