import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Amplify from "aws-amplify";
import amplifyconfig from "./Amplifyconfig";
import OwnAutenticator from "./OwnAutenticator";

import * as serviceWorker from "./serviceWorker";
import {BrowserRouter} from "react-router-dom";

function nohop() {}

if (process.env.REACT_APP_STAGE === "PRODUCTION") {
  console.log("production environment");
  console.log = nohop;
  console.error = nohop;
  console.warn = nohop;

  // window.onbeforeunload = function() {
  //   localStorage.clear();
  // };
}

Amplify.configure(amplifyconfig);

ReactDOM.render(
    <BrowserRouter>
      <OwnAutenticator />

      {/* <App /> */}
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
