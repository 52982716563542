import React, {Component} from "react";
import "./App.css";
import {Hub} from "aws-amplify";
import {authStore} from "./store/auth";

import Mainframe from "./components/mainframe/mainframe";
import LoginUI from "./components/mainframe/loginUI";

class App extends Component {
  constructor(props) {
    super(props);
    Hub.listen("auth", this, "stateListener");
    this.state = {
      isUserDataReady: false,
    };
  }
  onHubCapsule(capsule) {
    const {channel, payload} = capsule;
    if (channel === "auth") {
      this.onAuthEvent(payload);
    }
  }
  onAuthEvent(payload) {
    console.log("auth event", payload);

    if (payload["event"] === "UserDataReady") {
      console.log("groups", authStore.groups);
      this.setState({
        isUserDataReady: true,
      });
    }
  }
  render() {
    const isReady =
      this.state.isUserDataReady && this.props.authState === "signedIn";
    return (
      <div>
        {this.props.authState !== "signedIn" && <LoginUI />}
        {isReady && <Mainframe />}
      </div>
    );
  }
}

export default App;
