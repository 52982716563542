import { observable, decorate, action } from "mobx";

class AuthStore {
  auth = "";
  username = "";
  email = "";
  groups = [];
  // token = ""

  setUsername(name) {
    this.username = name;
  }

  setGroups(groups) {
    console.log("groups", groups);
    this.groups = groups;
  }

  setEmail(email) {
    this.email = email;
  }

  // getUser(){
  //     Auth.currentUserPoolUser()
  //         .then(resp=>{
  //             console.log("user pool",resp)
  //         })
  //         .catch(err=>{
  //             console.log(err)
  //         })
  //     console.log(Auth)
  //     Auth.currentUserInfo()
  //         .then(resp=>{
  //             console.log("user info",resp)
  //         })

  // }
}

decorate(AuthStore, {
  username: observable,
  email: observable,
  groups: observable,
  setUsername: action,
  setGroups: action,
  setEmail: action,
  getUser: action,
});

export const authStore = new AuthStore();
